import Glide from '@glidejs/glide';

const dataLayerEventEndingsByActionType = {
	complete: '_complete',
	skip: '_skip',
};

const dataLayerEventNamesByQuizIdAndIndex = {
	'quiz-wealth': [
		'quiz_wealth_1_primary_goal',
		'quiz_wealth_2_currently_feel',
		'quiz_wealth_3_desired_feel',
		'quiz_wealth_4_motivation_source',
		'quiz_wealth_5_biggest_obstacle',
		'quiz_wealth_6_desired_amount_mon',
		'quiz_wealth_7_specific_goal',
		'quiz_wealth_8_choose_voice',
		'quiz_wealth_9_name',
		'quiz_wealth_10_email',
	],
	'quiz-confidence': [
		'quiz_confidence_1_confidence_goal',
		'quiz_confidence_2_qualities',
		'quiz_confidence_3_obstacles',
		'quiz_confidence_4_vision',
		'quiz_confidence_5_choose_voice',
		'quiz_confidence_6_name',
		'quiz_confidence_7_email',
	],
	'quiz-career': [
		'quiz_career_1_career_goal',
		'quiz_career_2_obstacles',
		'quiz_career_3_qualities',
		'quiz_career_4_ideal_career',
		'quiz_career_5_choose_voice',
		'quiz_career_6_name',
		'quiz_career_7_email',
	],
	'quiz-goals': [
		'quiz_goals_1_main_life_goal',
		'quiz_goals_2_obstacles',
		'quiz_goals_3_qualities',
		'quiz_goals_4_feelings',
		'quiz_goals_5_choose_voice',
		'quiz_goals_6_name',
		'quiz_goals_7_email',
	],
	'quiz-weight-loss': [
		'quiz_weight_loss_1_weight_loss_goal',
		'quiz_weight_loss_2_weight_loss_obstacles',
		'quiz_weight_loss_3_exercise_preference',
		'quiz_weight_loss_4_fitness_goal_feelings',
		'quiz_weight_loss_5_choose_voice',
		'quiz_weight_loss_6_name',
		'quiz_weight_loss_7_email',
	],
	'quiz-love': [
		'quiz_love_1_primary_goal',
		'quiz_love_2_attitude_towards_love',
		'quiz_love_3_qualities',
		'quiz_love_4_obstacles',
		'quiz_love_5_ideal_relationship',
		'quiz_love_6_choose_voice',
		'quiz_love_7_name',
		'quiz_love_8_email',
	],
	'quiz-health': [
		'quiz_health_1_health_goal',
		'quiz_health_2_health_obstacles',
		'quiz_health_3_health_improvement',
		'quiz_health_4_health_feelings',
		'quiz_health_5_choose_voice',
		'quiz_health_6_name',
		'quiz_health_7_email',
	],
	'quiz-mindfulness': [
		'quiz_mindfulness_1_mindfulness_goal',
		'quiz_mindfulness_2_mindfulness_obstacles',
		'quiz_mindfulness_3_mindfulness_development',
		'quiz_mindfulness_4_mindfulness_feelings',
		'quiz_mindfulness_5_choose_voice',
		'quiz_mindfulness_6_name',
		'quiz_mindfulness_7_email',
	],
	'quiz-motivation': [
		'quiz_motivation_1_goal',
		'quiz_motivation_2_obstacles',
		'quiz_motivation_3_source',
		'quiz_motivation_4_feelings',
		'quiz_motivation_5_factor',
		'quiz_motivation_6_choose_voice',
		'quiz_motivation_7_name',
		'quiz_motivation_8_email',
	],
	'quiz-happiness': [
		'quiz_happiness_1_goal',
		'quiz_happiness_2_happiness_obstacles',
		'quiz_happiness_3_happiness_source',
		'quiz_happiness_4_happiness_feelings',
		'quiz_happiness_5_happiness_factor',
		'quiz_happiness_6_choose_voice',
		'quiz_happiness_7_name',
		'quiz_happiness_8_email',
	],
	'quiz-custom-goal': [
		'quiz_custom_goal_1_goal',
		'quiz_custom_goal_2_feel',
		'quiz_custom_goal_3_fears',
		'quiz_custom_goal_4_obstacles',
		'quiz_custom_goal_5_traits',
		'quiz_custom_goal_6_choose_voice',
		'quiz_custom_goal_7_name',
		'quiz_custom_goal_8_email',
	],
	'quiz-addiction': [
		'quiz_addiction_1_goal',
		'quiz_addiction_2_obstacles',
		'quiz_addiction_3_source',
		'quiz_addiction_4_feelings',
		'quiz_addiction_5_factor',
		'quiz_addiction_6_choose_voice',
		'quiz_addiction_7_name',
		'quiz_addiction_8_email',
	],
};

const dataLayerFiredEvents = {};

function addEventToFiredEvents(quizId, index, action) {
	const event =
		dataLayerEventNamesByQuizIdAndIndex[quizId][index] +
		dataLayerEventEndingsByActionType[action];

	dataLayerFiredEvents[quizId] ||= [];

	if (!dataLayerFiredEvents[quizId].includes(event)) {
		dataLayerFiredEvents[quizId].push(event);
	}
}

function hasEventFired(quizId, index, action) {
	const event =
		dataLayerEventNamesByQuizIdAndIndex[quizId][index] +
		dataLayerEventEndingsByActionType[action];
	return (
		dataLayerFiredEvents[quizId] && dataLayerFiredEvents[quizId].includes(event)
	);
}

function sendDataLayerSlideChangeEvent(currentIndex, quizId, action) {
	if (hasEventFired(quizId, currentIndex, action)) {
		return;
	}

	window.dataLayer.push({
		event:
			dataLayerEventNamesByQuizIdAndIndex[quizId][currentIndex] +
			dataLayerEventEndingsByActionType[action],
	});

	addEventToFiredEvents(quizId, currentIndex, action);
}

function sendDataLayerQuizSubmitEvent(quizId) {
	quizId = quizId.replace(/-/g, '_');

	window.dataLayer ||= [];
	window.dataLayer.push({
		event: `${quizId}_submit`,
	});
}

function goToNextSlideAndSendToGA(slider, quizId, action = 'complete') {
	const targetElement = document.querySelector(
		`#${quizId} .glide__slide--active`,
	);
	const {parentElement} = targetElement;
	const allElements = parentElement.querySelectorAll('.glide__slide');

	const index = Array.prototype.indexOf.call(allElements, targetElement);
	console.log('index', index);

	sendDataLayerSlideChangeEvent(index, quizId, action);

	slider.go('>');
}

function canGoNext(button) {
	const quizScreen = button.closest('.quiz__screen');

	if (!quizScreen) {
		return false;
	}

	const radioInputs = quizScreen.querySelectorAll('.quiz-option__input-radio');

	for (const radio of radioInputs) {
		if (radio.checked) {
			return true;
		}
	}

	const textInputs = quizScreen.querySelectorAll(
		'.quiz-option__input-text, .quiz-option__input-textarea',
	);

	for (const textInput of textInputs) {
		if (textInput.value.trim() !== '') {
			return true;
		}
	}

	const checkboxInputs = quizScreen.querySelectorAll(
		'.quiz-option__input-checkbox',
	);

	for (const checkbox of checkboxInputs) {
		if (checkbox.checked) {
			return true;
		}
	}

	if (radioInputs.length > 0 || checkboxInputs.length > 0) {
		showErrorMessage('make-your-choice', quizScreen);
	} else if (textInputs.length > 0 || textInputs.length > 0) {
		showErrorMessage('empty-input', quizScreen);
	}

	button.classList.add('animation-shake');

	return false;
}

function showErrorMessage(error, quizScreen) {
	const errorMessage = quizScreen.querySelector(`.error-message--${error}`);

	if (errorMessage) {
		errorMessage.classList.remove('hidden');
	}
}

function hideErrorMessages(button) {
	const quizScreen = button.closest('.quiz__screen');
	const errorMessages = quizScreen.querySelectorAll('.error-message');

	errorMessages.forEach(errorMessage => {
		errorMessage.classList.add('hidden');
	});
}

function addQuizSlider(quizId) {
	const slider = new Glide(`#${quizId}`, {
		type: 'slider',
		startAt: 0,
		perView: 1,
		keyboard: false,
		animationDuration: 750,
		swipeThreshold: false,
		dragThreshold: false,
		rewind: false,
	});

	const quizElement = document.getElementById(quizId);

	quizElement
		.querySelector('.quiz__form')
		.addEventListener('keydown', event => {
			if (
				event.key === 'Enter' ||
				event.key === 'NumpadEnter' ||
				event.keyCode === 229 ||
				event.keyCode === 13
			) {
				event.preventDefault();

				const userAgent =
					navigator.userAgent || navigator.vendor || window.opera;
				const isAndroid = /android/i.test(userAgent);
				const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

				if (!isAndroid && !isIOS) {
					const button = document.querySelector(
						'.glide__slide--active .quiz__footer',
					);

					if (canGoNext(button)) {
						hideErrorMessages(button);
						goToNextSlideAndSendToGA(slider, quizId, 'complete');
					}
				}
			}
		});

	quizElement.querySelectorAll('.quiz__button-next').forEach(button => {
		button.addEventListener('click', event => {
			event.stopPropagation();
			if (canGoNext(button)) {
				hideErrorMessages(button);
				goToNextSlideAndSendToGA(slider, quizId, 'complete');
			}
		});
	});

	quizElement.querySelectorAll('.quiz__button-skip').forEach(button => {
		button.addEventListener('click', () => {
			hideErrorMessages(button);
			goToNextSlideAndSendToGA(slider, quizId, 'skip');
		});
	});

	quizElement.querySelectorAll('.quiz__button-back').forEach(button => {
		button.addEventListener('click', () => {
			hideErrorMessages(button);
			slider.go('<');
		});
	});

	quizElement.querySelectorAll('.quiz-option-next').forEach(button => {
		button.addEventListener('click', () => {
			hideErrorMessages(button);
			const label = button.querySelector('.quiz-option__label');
			if (label) {
				const handleAnimationEnd = () => {
					goToNextSlideAndSendToGA(slider, quizId, 'complete');
					label.removeEventListener('animationend', handleAnimationEnd);
				};

				label.addEventListener('animationend', handleAnimationEnd);
			} else {
				hideErrorMessages(button);
				goToNextSlideAndSendToGA(slider, quizId, 'complete');
			}
		});
	});

	slider.mount();
}

function initFormHandlers() {
	document.querySelectorAll('.quiz__form').forEach(form => {
		const quiz = form.closest('.quiz');
		let quizLoader;

		if (quiz) {
			quizLoader = quiz.querySelector('.quiz-loading');
		}

		form.addEventListener('submit', event => {
			event.preventDefault();
			if (quizLoader) {
				quizLoader.classList.remove('hidden');
			}

			const formData = new FormData(form);
			const data = {
				voice: '',
				voiceType: '',
				name: '',
				email: '',
				goal: '',
				meditationPackId: '',
				answers: {},
			};

			if (formData) {
				formData.forEach((value, key) => {
					if (key === 'voice') {
						const selectedVoice = form.querySelector(
							'input[name="voice"]:checked',
						);
						if (selectedVoice) {
							data.voice = value;
							data.voiceType = selectedVoice.getAttribute('data-type');
						}
					} else if (key === 'name' && value) {
						data.name = value;
					} else if (key === 'email' && value) {
						data.email = value;
					} else if (key === 'goal' && value !== '' && value.length > 0) {
						data.goal = value;
					} else if (key === 'meditationPackId') {
						data.meditationPackId = value;
					} else {
						const questionElement = form.querySelector(`[name="${key}"]`);
						if (questionElement) {
							const question = questionElement.getAttribute('data-question');
							const answer = Array.isArray(value) ? value.join(' ') : value;
							const inputType = questionElement.getAttribute('type');
							if (answer && !(inputType === 'radio' && value === 'on')) {
								const index = Object.keys(data.answers).length + 1;
								data.answers[index] = {
									question,
									answer,
								};
							}
						}
					}
				});
			}

			fetch(form.action, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})
				.then(response => {
					if (response.ok) {
						return response.json();
					}

					throw new Error('Network response was not ok.');
				})
				.then(result => {
					if (result.link) {
						if (quiz && quiz.id) {
							sendDataLayerQuizSubmitEvent(quiz.id);
						}

						setTimeout(() => {
							window.location.href = result.link;
						}, 500);
					} else {
						if (quizLoader) {
							quizLoader.classList.add('hidden');
						}

						console.log('Success:', result);
					}
				})
				.catch(error => {
					console.error('Error:', error);
				});
		});
	});
}

function initOtherFieldInputControllers() {
	const otherInputs = document.querySelectorAll('.quiz-option__input-other');

	otherInputs.forEach(input => {
		input.addEventListener('input', () => {
			const parentLabel = input.closest('.quiz-option__label-other');
			if (input.value.trim() === '') {
				parentLabel.classList.remove('quiz-option__label-other--filled');
			} else {
				parentLabel.classList.add('quiz-option__label-other--filled');
			}
		});
	});
}

function initAudioChoiceInputs() {
	const audioChoiceInputs = document.querySelectorAll(
		'.quiz-option__input-audio-choice',
	);
	const audioElements = new Map();

	function stopAllAudio() {
		audioElements.forEach((audio, input) => {
			audio.pause();
			audio.currentTime = 0;
			const parentChoice = input.closest('.quiz-option-audio-choice');
			if (parentChoice) {
				parentChoice.classList.remove('quiz-option-audio-choice--playing');
			}
		});
	}

	audioChoiceInputs.forEach(input => {
		const audioSrc = input.getAttribute('data-audio');
		if (audioSrc) {
			const audio = new Audio(audioSrc);
			audioElements.set(input, audio);

			input.addEventListener('change', () => {
				if (input.checked) {
					stopAllAudio();
					audio
						.play()
						.then(() => {})
						.catch(error => {
							console.error('Failed to play audio:', error);
						});
					const parentChoice = input.closest('.quiz-option-audio-choice');
					if (parentChoice) {
						parentChoice.classList.add('quiz-option-audio-choice--playing');
					}
				}
			});

			audio.addEventListener('ended', () => {
				const parentChoice = input.closest('.quiz-option-audio-choice');
				if (parentChoice) {
					parentChoice.classList.remove('quiz-option-audio-choice--playing');
				}
			});

			const parentLabel = input.closest('.quiz-option__label-audio-choice');
			if (parentLabel) {
				parentLabel.addEventListener('click', event => {
					if (input.checked) {
						event.preventDefault();
						audio.pause();
						audio.currentTime = 0;
						const parentChoice = input.closest('.quiz-option-audio-choice');
						if (parentChoice) {
							parentChoice.classList.remove(
								'quiz-option-audio-choice--playing',
							);
						}
					}
				});
			}

			const parentChoice = input.closest('.quiz-option-audio-choice');
			if (parentChoice) {
				parentChoice.addEventListener('click', () => {
					if (
						parentChoice.classList.contains('quiz-option-audio-choice--playing')
					) {
						stopAllAudio();
					}
				});
			}
		} else {
			console.warn('No audio source found for input:', input);
		}
	});

	const nextButtons = document.querySelectorAll(
		'.quiz__button-next, .quiz__button-submit',
	);
	nextButtons.forEach(button => {
		button.addEventListener('click', stopAllAudio);
	});
}

function initOtherInputs() {
	const quizOptionOthers = document.querySelectorAll('.quiz-option-other');

	quizOptionOthers.forEach(option => {
		option.addEventListener('click', () => {
			const input = option.querySelector('.quiz-option__input-other');
			if (input) {
				input.focus();
			}
		});
	});
}

export function initQuizzes() {
	document.querySelectorAll('.quiz-glide').forEach(element => {
		addQuizSlider(element.id);
		element.classList.add('hidden');
		element.classList.remove('visibility-hidden');
	});

	initFormHandlers();
	initOtherFieldInputControllers();
	initAudioChoiceInputs();
	initOtherInputs();

	(function () {
		function adjustModalHeights() {
			const modals = document.querySelectorAll('.quiz');
			modals.forEach(modal => {
				modal.style.height = window.innerHeight + 'px';
			});
		}

		window.addEventListener('resize', adjustModalHeights);
		window.addEventListener('orientationchange', adjustModalHeights);
		document.addEventListener('DOMContentLoaded', adjustModalHeights);

		new MutationObserver(adjustModalHeights).observe(document.body, {
			childList: true,
			subtree: true,
		});
	})();
}
