import ScrollMagic from 'scrollmagic';
import {initVideoPlayers} from './components/video-players.js';
import {initCommonQuestions} from './components/common-questions.js';
import {initPeopleQuotes} from './components/people-quotes.js';
import {initPeopleStories} from './components/people-stories.js';
import {initModals} from './components/modals.js';
import {initAudioSampleModals} from './components/modal-audio-samples.js';
import {initQuizzes} from './components/quiz.js';

document.addEventListener('DOMContentLoaded', () => {
	const btnCtaLeadScreen = document.getElementById('btn-cta-lead-screen');

	if (btnCtaLeadScreen) {
		btnCtaLeadScreen.addEventListener('click', () => {
			window.dataLayer ||= [];
			window.dataLayer.push({
				event: 'btn_create_session_click',
			});
		});
	}

	const controller = new ScrollMagic.Controller();
	const initFlags = {
		videoPlayers: false,
		commonQuestions: false,
		peopleQuotes: false,
		peopleStories: false,
		audioSampleModals: false,
	};

	new ScrollMagic.Scene({
		triggerElement: '.page-section.audio-samples',
		triggerHook: 0.8,
	})
		.on('enter', () => {
			if (!initFlags.videoPlayers) {
				initModals();
				initAudioSampleModals();
				initQuizzes();
				initVideoPlayers();
				initFlags.videoPlayers = true;
			}
		})
		.addTo(controller);

	new ScrollMagic.Scene({
		triggerElement: '.page-section.bonuses',
		triggerHook: 0.8,
	})
		.on('enter', () => {
			if (!initFlags.commonQuestions) {
				initCommonQuestions();
				initFlags.commonQuestions = true;
			}
		})
		.addTo(controller);

	new ScrollMagic.Scene({
		triggerElement: '.page-section.questions',
		triggerHook: 0.8,
	})
		.on('enter', () => {
			if (!initFlags.peopleQuotes) {
				initPeopleQuotes();
				initFlags.peopleQuotes = true;
			}
		})
		.addTo(controller);

	new ScrollMagic.Scene({
		triggerElement: '.page-section.people-quotes',
		triggerHook: 0.8,
	})
		.on('enter', () => {
			if (!initFlags.peopleStories) {
				initPeopleStories();
				initFlags.peopleStories = true;
			}
		})
		.addTo(controller);
});
