import Glide from '@glidejs/glide';

export function initPeopleStories() {
	const prevBtn = document.querySelector(
		'.people-stories-list__control-btn--prev',
	);
	const nextBtn = document.querySelector(
		'.people-stories-list__control-btn--next',
	);
	const paginationBtns = document.querySelectorAll(
		'.people-stories-list__pagination-page',
	);

	const slider = new Glide('.people-stories-list-glide', {
		type: 'slider',
		startAt: 0,
		perView: 1,
		keyboard: true,
		swipeThreshold: 10,
		perTouch: 1,
		touchAngle: 35,
		animationDuration: 250,
		rewind: true,
		rewindDuration: 500,
	});

	const updatePagination = () => {
		const currentIndex = slider.index;
		paginationBtns.forEach((btn, index) => {
			if (index === currentIndex) {
				btn.classList.add('people-stories-list__pagination-page--current');
			} else {
				btn.classList.remove('people-stories-list__pagination-page--current');
			}
		});
	};

	prevBtn.addEventListener('click', () => {
		slider.go('<');
	});

	nextBtn.addEventListener('click', () => {
		slider.go('>');
	});

	paginationBtns.forEach(btn => {
		btn.addEventListener('click', () => {
			const targetIndex = btn.getAttribute('data-target');
			slider.go(`=${targetIndex}`);
		});
	});

	slider.on('run.after', updatePagination);

	slider.mount();

	updatePagination();
}
