const firedVideoEvents = [];

export function initVideoPlayers() {
	const videoPlayers = document.querySelectorAll('.video-player');

	videoPlayers.forEach(video => {
		const playButton = video.parentElement.querySelector('.btn-play-video');

		if (video && playButton) {
			playButton.addEventListener('click', () => {
				if (playButton.classList.contains('btn-play-video--autoplay')) {
					btnHandleAutoplayVideo(playButton, video);
				} else {
					btnPlayVideo(playButton, video);
				}
			});
		}
	});
}

function btnHandleAutoplayVideo(button, video) {
	video.currentTime = 0;
	video.muted = false;
	video.controls = true;
	button.style.display = 'none';

	const gaEvent = video.getAttribute('data-ga-event');
	if (gaEvent) {
		triggerDataLayerEvent(gaEvent);
	}
}

function btnPlayVideo(button, video) {
	video.play();
	video.controls = true;
	button.style.display = 'none';

	const gaEvent = video.getAttribute('data-ga-event');
	if (gaEvent) {
		const handleTimeUpdate = () => {
			if (video.currentTime >= 15) {
				triggerDataLayerEvent(gaEvent);
				video.removeEventListener('timeupdate', handleTimeUpdate);
			}
		};

		video.addEventListener('timeupdate', handleTimeUpdate);
	}
}

function triggerDataLayerEvent(event) {
	if (!firedVideoEvents.includes(event)) {
		window.dataLayer ||= [];
		window.dataLayer.push({
			event,
		});
		firedVideoEvents.push(event);
	}
}
