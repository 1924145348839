import Glide from '@glidejs/glide';

export function initPeopleQuotes() {
	const prevBtn = document.querySelector(
		'.people-quotes-list__control-btn--prev',
	);
	const nextBtn = document.querySelector(
		'.people-quotes-list__control-btn--next',
	);

	const slider = new Glide('.people-quotes-list-glide', {
		type: 'slider',
		startAt: 0,
		perView: 1,
		keyboard: true,
		swipeThreshold: 10,
		perTouch: 1,
		touchAngle: 35,
		animationDuration: 250,
		rewind: true,
		rewindDuration: 500,
	});

	prevBtn.addEventListener('click', () => {
		slider.go('<');
	});

	nextBtn.addEventListener('click', () => {
		slider.go('>');
	});

	slider.mount();
}
